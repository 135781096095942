.active-nav {
    color: var(--aqua-blue) !important;
}

.active-nav .nav-icon {
    background-color: var(--aqua-blue) !important;
    transition: all 0.3s 0.3s;
}

.active-nav .nav-icon img {
    filter: none !important;
    transition: all 0.3s 0.3s !important;
}