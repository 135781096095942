.span {
    font-weight: bold;
}

.tooltip-container {
    position: absolute;
    top: -45px;
    left: 80px;
    z-index: 10;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tooltip-text-box {
    box-shadow: 0px 8px 16px -2px rgba(27, 33, 44, 0.12);
    padding: 10px 15px;
    background-color: var(--background);
    border-radius: 8px;
}

.tooltip-text {
    font-size: 12px !important;
}

.tooltip-triangle {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--background);
    box-shadow: 0px 8px 16px -2px rgba(27, 33, 44, 0.12);
}

.fade-out {
    animation: fadeOut 0.5s;
}

@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}