.flex-column-vert-hor-center {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}

.flex-row-vert-hor-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.vibrate {
    animation: shake 0.2s ease-in-out 0s 2;
}

.image {
    width: 100%;
}

@keyframes shake {
    0% { margin-left: 0rem; }
    25% { margin-left: 0.5rem; }
    75% { margin-left: -0.5rem; }
    100% { margin-left: 0rem; }
}