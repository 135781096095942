/* Default css styles for all html */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  height: 100%;
}
body {
  --screen-value: 0px;
  font-size: 1.6rem;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
}
#root {
  height: 100%;
}

/* Select and input images appearance */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

/* Scrollbar */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  padding: 10px 0;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background-color: inherit;
  border-radius: 8px;
}
:hover::-webkit-scrollbar-thumb {
  background: var(--nav);
  transition: all .8s;
}

/* Colors */
:root {
  --black: #000000;
  --white: #FFFFFF;
  --primary: #0878ff;
  --primaryO: #0878ff14;
  --lightBlue: #3ca2f6;
  --darkBlue: #000853;
  --darkBlueA: #000A65;
  --aqua-blue: #5AE1E2;
  --principalGray: #ACB9C9;
  --principalGrayA: #acb9c986;
  --nav: #CED9DF;
  --menuIcon: #5D6C7C;
  --grayTextA: #616f81b2;
  --darkGrayText: #2F363E;
  --background: #F6F7FB;
  --lightGray: #E6ECF6;
  --principalGreen: #91e02b;
  --error: #d32f2f;
  --error-opacity: #FEF1F2;
  --button-blue: #0516B1;
}
